import React from 'react'
import {
    Container,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Divider
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReviewsIcon from '@mui/icons-material/Reviews';
import FeedbackIcon from '@mui/icons-material/Feedback';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AddReview from './add_review';
import Reviews from './reviews';
const heads = [
    {
        head : 'Reviews',
        icon : <ReviewsIcon />,
        value : <Reviews />
    },
    {
        head : 'Add Review',
        icon : <RateReviewIcon />,
        value : <AddReview />
    }

]
export default function RevHome(){
    
    const [selected, setSelected] = React.useState(null)

    return(
        <Container>
            <Grid container spacing={1} >
                <Grid item xs={12} component={Paper} sm={4}>
                    {
                        heads.map(item=>
                            <div key={item.head}>
                                <ListItem  button>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.head}
                                    />

                                    <IconButton onClick={()=>setSelected(item.value)}>
                                        <ChevronRightIcon />
                                    </IconButton>

                                </ListItem>
                                <Divider />
                            </div>
                            
                            )
                    }
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper>
                    {
                        selected
                    }  
                    </Paper>  

                </Grid>
            </Grid>
        </Container>
    )
}