import React, { Component } from 'react';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, IconButton, Tooltip, LinearProgress,TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { Api } from '../../process/api';
import Resizer from "react-image-file-resizer";
import SnakBar from '../../consts/massage';
export default function DepartmentUpdate({data,setRefresh}){
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const [loading, setLoading] = React.useState(false)
    const [formData,setFormData] = React.useState('')
    const [err, setErr] = React.useState({})
    const [massg, setMassg] = React.useState({})
    React.useEffect(()=>{
        if(!data){
            
        }else {
            setFormData({
                name : data.name,
                id : data.id,
                content : data.consent_form
            })
            const html =  data.consent_form;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            }
        }
    },[data.id])
    
    const handleChange = (event)=>{

            setFormData({
                ...formData,
                [event.target.name] : event.target.value
            })
        
    }

    const onSubmit = ()=>{

            setLoading(true)
            
            const data = new FormData
            data.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            data.append('name', formData.name)
            data.append('id', formData.id)
            fetch(Api+'departmentUpdate',{
                method : 'POST',
                body : data
            })
            .then(Response=>Response.json())
            .then(data=>{
                setRefresh(Math.random)
                if(data.status === 201){
                    
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'success'
                    })
                }else{
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
                setLoading(false)
            })
            .catch(err=>{
                console.log(err)
                setMassg({
                    open : true,
                    massg : 'Faild to connect to server',
                    severity : 'error'
                })
            })
            
    }

    

    return (
        <div>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
            
            <TextField
                name = "name"
                label='Name of new head'
                value={formData.name}
                onChange={handleChange}
                error={err.name}
                fullWidth
                style={{margin: 10}}
            />
           
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{border : '1px solid #e5e5e5',minHeight : 200}}
                toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
               
                />
                <textarea
                disabled
                style={{display: 'none'}}
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                // onChange={({target : {value}})=>setData(value)}
            />
            <SnakBar setMassg={setMassg} massg={massg} />
        </div>
    )
}
