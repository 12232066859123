import React from "react";
import {
    Button,
    Container,
    Grid,
    Paper,
    TextField,
    LinearProgress
} from '@mui/material'
import Massages from "./massages";
import { Api } from "../../process/api";

export default function Contact(){
    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'ContactInfo')
        .then(Response=>Response.json())
        .then(data=>{
            setLoading(false)
            setData(data)
        })
    },[])

    const onChange = (e)=>{
        setData({
            ...data,
            [e.target.name] : e.target.value
        })
    }

    const onUpdate = ()=>{
        setLoading(true)
        fetch(Api+'updateContact',{
            method : 'POST',
            body : JSON.stringify(data)
        })
        .then(Response=>Response.json())
        .then(data=>{
            setLoading(false)
        })
    }
    return(
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Paper style={{display:'flex',flexDirection:'column',justifyContent: 'center',alignItems: 'center'}}>
                    {
                        loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                    }
                        <TextField
                            helperText="contact No"
                            value={data.contact_no}
                            variant="outlined"
                            style={{margin : 10}}
                            name="contact_no"
                            onChange={onChange}
                            
                        />
                        <TextField
                            helperText="Email"
                            value={data.email}
                            variant="outlined"
                            name="email"
                            style={{margin : 10}}
                            onChange={onChange}
                            
                        />
                        <TextField
                            helperText="Address"
                            value={data.address}
                            variant="outlined"
                            name="address"
                            style={{margin : 10}}
                            onChange={onChange}
                            multiline
                            
                        />
                        <Button disabled={loading} variant="contained" onClick={onUpdate} color="primary">
                            Update
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Massages />
                </Grid>
            </Grid>
        </Container>
    )
}