import React, { Component } from 'react';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, IconButton, Tooltip, LinearProgress, Typography, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { Api } from '../../process/api';
import Resizer from "react-image-file-resizer";
import SnakBar from '../../consts/massage';
export default function DepartmentInsert({setRefresh}){
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const [loading, setLoading] = React.useState(false)
    const [formData,setFormData] = React.useState('')
    const [err, setErr] = React.useState({})
    const [massg, setMassg] = React.useState({})

    
    const handleChange = (event)=>{

            setFormData({
                ...formData,
                [event.target.name] : event.target.value
            })
        
    }

    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
            const data = new FormData
            data.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            data.append('name', formData.name)
            fetch(Api+'DepartmentInsert',{
                method : 'POST',
                body : data
            })
            .then(Response=>Response.json())
            .then(data=>{
                if(data.status === 201){
                    setRefresh(Math.random())
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'success'
                    })
                }else{
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
                setLoading(false)
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    massg : 'Faild to connect to server',
                    severity : 'error'
                })
            })
            }else{
                setMassg({
                    open : true,
                    massg : 'Please fill all required filed',
                    severity : 'error'
                })
            }
    }

    const validate = ()=>{
        let valid = true
        let err = {}

        if(!formData.name){
            valid = false
            err['name'] = true
        }
        if(editorState === EditorState.createEmpty()){
            valid = false
            err['editorState'] = true
        }

        setErr(err)

        return valid
    }

    return (
        <div>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }

            <TextField
                name = "name"
                label='Name of department'
                value={formData.name}
                onChange={handleChange}
                error={err.name}
                fullWidth
                style={{margin: 10}}
            />
            <Typography style={{marginLeft: 10}}>INFORMED CONSENT FORM</Typography>
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{border : '1px solid ',minHeight : 200, borderColor : err.editorState ? 'red' : '#e5e5e5'}}
                toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
               
                />
                <textarea
                disabled
                style={{display: 'none'}}
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                // onChange={({target : {value}})=>setData(value)}
            />
            <SnakBar setMassg={setMassg} massg={massg} />
        </div>
    )
}
