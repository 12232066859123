import React, { Component } from 'react';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, IconButton, Tooltip, LinearProgress,TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { Api } from '../../process/api';
import Resizer from "react-image-file-resizer";
import SnakBar from '../../consts/massage';
export default function ContentUpdate(props){
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const [loading, setLoading] = React.useState(false)
    const [formData,setFormData] = React.useState('')
    const [err, setErr] = React.useState({})
    const [massg,setMassg] = React.useState({})
    React.useEffect(()=>{
        if(!props.content){
            
        }else {
            setFormData({
                name : props.content.name,
                image : props.content.image,
                type : props.content.type,
                id : props.content.id,
                p_value : props.content.priority_value
            })
            const html =  props.content.content;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            }
        }
    },[props.content.id])
    const handleChangeImage = (event)=>{
        if(!event.target.files[0]){  
            setFormData({
                ...formData,
                [event.target.name] : event.target.value
            })
        }else {
            
            try {
                Resizer.imageFileResizer(
                event.target.files[0],
                1000,
                1000,
                "PNG",
                80,
                0,
                (uri) => {
                    
                    setFormData({...formData,image : uri});
                },
                "file",
                200,
                200
                );
    
            
            } catch (err) {
                console.log(err);
            }
        }
    }
    const handleChange = (event)=>{

            setFormData({
                ...formData,
                [event.target.name] : event.target.value
            })
        
    }

    const onSubmit = ()=>{

            setLoading(true)
            const data = new FormData
            data.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            data.append('type',formData.type)
            data.append('name', formData.name)
            data.append('image', formData.image)
            data.append('prof_name', formData.image)
            data.append('id', formData.id)
            data.append('p_value',formData.p_value)
            fetch(Api+'ContentUpdateAbout',{
                method : 'POST',
                body : data
            })
            .then(Response=>Response.json())
            .then(data=>{
                console.log(data)
                setLoading(false)
                if(data.status === 201){
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'success'
                    })
                }else{
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    massg : 'Faild to connect to the server',
                    severity : 'error'
                })
            })
            
    }

    

    return (
        <div>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
            
            <TextField
                name = "name"
                label='Name of new head'
                value={formData.name}
                onChange={handleChange}
                error={err.name}
                fullWidth
                style={{margin: 10}}
            />
            <TextField
                name = "p_value"
                label='priority value'
                value={formData.p_value}
                onChange={handleChange}
                error={err.p_value}
                fullWidth
                style={{margin: 10}}
                type="number"
            />
            <TextField
                onChange={handleChangeImage}
                error={err.image}
                fullWidth
                style={{margin: 10}}
                type="file"
                helperText="Select an image to display (best resolution:1280 * 850)"
            />

            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{border : '1px solid #e5e5e5',minHeight : 200}}
                toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
               
                />
                <textarea
                disabled
                style={{display: 'none'}}
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                // onChange={({target : {value}})=>setData(value)}
            />


            <SnakBar setMassg={setMassg} massg={massg} />
            
        </div>
    )
}
