import React, { Component } from 'react';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, IconButton, Tooltip, LinearProgress, Typography, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { Api } from '../../process/api';
import Resizer from "react-image-file-resizer";
import SnakBar from '../../consts/massage';
export default function ContentInsert(props){
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const [loading, setLoading] = React.useState(false)
    const [formData,setFormData] = React.useState('')
    const [err, setErr] = React.useState({})
    const [massg,setMassg] = React.useState({})

    const handleChangeImage = (event)=>{
        if(!event.target.files[0]){  
            setFormData({
                ...formData,
                [event.target.name] : event.target.value
            })
        }else {
            
            try {
                Resizer.imageFileResizer(
                event.target.files[0],
                1000,
                1000,
                "PNG",
                80,
                0,
                (uri) => {
                    
                    setFormData({...formData,image : uri});
                },
                "file",
                200,
                200
                );
    
            
            } catch (err) {
                console.log(err);
            }
        }
    }
    const handleChange = (event)=>{

            setFormData({
                ...formData,
                [event.target.name] : event.target.value
            })
        
    }

    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
            const data = new FormData
            data.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            data.append('type',props.type)
            data.append('name', formData.name)
            data.append('image', formData.image)
            data.append('p_value',formData.p_value)
            fetch(Api+'ContentInsert',{
                method : 'POST',
                body : data
            })
            .then(Response=>Response.json())
            .then(data=>{
                setLoading(false)
                if(data.status === 201){
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'success'
                    })
                }else{
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    massg : 'Faild to connect to the server',
                    severity : 'error'
                })
            })
            }else{
                setMassg({
                    open : true,
                    massg : 'Please fill all required fill',
                    severity : 'error'
                })
            }
    }

    const validate = ()=>{
        let valid = true
        let err = {}

        if(!formData.name){
            valid = false
            err['name'] = true
        }
        if(!formData.image){
            valid = false
            err['image'] = true
        }
        if(!formData.p_value){
            valid = false
            err['p_value'] = true
        }
        if(editorState === EditorState.createEmpty()){
            valid = false
            err['editorState'] = true
        }

        setErr(err)

        return valid
    }

    return (
        <div>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }

            <TextField
                name = "name"
                label='Name of new head'
                value={formData.name}
                onChange={handleChange}
                error={err.name}
                fullWidth
                style={{margin: 10}}
            />
            <TextField
                onChange={handleChangeImage}
                error={err.image}
                fullWidth
                style={{margin: 10}}
                type="file"
                helperText="Select an image to display (best resolution:1280 * 850)"
            />
            <TextField
                name = "p_value"
                label='priority value'
                value={formData.p_value}
                onChange={handleChange}
                error={err.p_value}
                fullWidth
                style={{margin: 10}}
                type="number"
            />
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{border : '1px solid ',minHeight : 200, borderColor : err.editorState ? 'red' : '#e5e5e5'}}
                toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
               
                />
                <textarea
                disabled
                style={{display: 'none'}}
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                // onChange={({target : {value}})=>setData(value)}
            />
            <SnakBar setMassg={setMassg} massg={massg} />
        </div>
    )
}
