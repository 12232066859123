import React from "react";
import {
    Container,
    Grid,
    ListItem,
    ListItemText,
    IconButton,
    Paper,
    Divider,
    Menu,
    MenuItem,
    Typography
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AboutEdit from "./aboutUs";
import AboutHomeEdit from "./about_home";
import WellCome from "./wellcome";
import { Api } from "../../process/api";
import ContentInsert from "./contentInsert";
import ContentUpdate from "./contentUpdate";
import DeleteIcon from '@mui/icons-material/Delete';
const contentList = [
    {
        name : "About Us",
        // value : <AboutEdit />
        value : "Aboutus"

    },
    {
        name : "Tagline",
        value : 'tagline'
        
    },
    {
        name : "Others",
        value : 'others'
        
    },
    
]


export default function Contents(){


    const [selected, setSelected] = React.useState(null)
    const [menu, setMenue] = React.useState(false)
    const [subMenu, setSubmenu] = React.useState([])
    const [menuE1, setMenuE1] = React.useState(null)

    const fetchData = (prop,value)=>{
        
            setMenuE1(value)
            fetch(Api+'ContentCol&type='+prop)
            .then(Response=>Response.json())
            .then(data=>{
               if(Array.isArray(data)){
                     data.push({id : data.length + 1,type : prop,name : 'Add new', content : 'addnew'})
                     setSubmenu(data)
                        
               }
            })
            .catch(err=>console.log(err))
    }
    const handleClick = (val,value)=>{
        setMenue(true)
        if(val === 'Aboutus'){
            fetchData('about_us',value)
        }else if(val === 'tagline'){
            fetchData('tagline',value)
        }
        else if(val === 'others'){
            fetchData('others',value)
        }
        else {
            setSelected(val)
        }
    }

    const handleSubMenue = (val,content)=>{
        setMenue(false)
        setMenuE1(null)
        if(content.name === 'Add new'){
            setSelected(<ContentInsert type={val} />)
        }
        else{
            setSelected(<ContentUpdate content={content} />)
        }
    }

    const deleteContent = (prop)=>{
        setMenue(false)
        fetch(Api+'deleteContent&id='+prop.id)
        .then(res=>res.json())
        .then(data=>{
            setSelected(null)
            if(data.status === 201){
                fetchData(prop.type)
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }
    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                <Paper>
                {
                    contentList.map((item,index)=>
                        <div key={index}>
                        <ListItem >
                            <ListItemText 
                                primary={item.name}
                            />
                            <IconButton onClick={({currentTarget : value})=>handleClick(item.value,value)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItem>
                        <Divider />
                        <Menu
                           anchorEl={menuE1}
                           open={menu}
                           onClose={()=>setMenue(false)}
                           MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        >
                        {
                            subMenu.map(item=>
                               <div>
                                   <MenuItem key={item.id} onClick={()=>handleSubMenue(item.type,item)}>
                                       <Typography>{item.name}</Typography>
                                       {
                                           item.name === 'Add new' ? '' : (
                                            <IconButton onClick={()=>deleteContent(item)}>
                                                    <DeleteIcon />
                                            </IconButton>
                                           )
                                       }
                                   </MenuItem>
                                   <Divider />
                                </div> 
                            )
                        }

                        </Menu>
                        </div>
                    )
                }
                </Paper>
                </Grid>
                <Grid item xs={12} sm={9}>
                    {
                        selected
                    }
                </Grid>
            </Grid>
            
        </Container>
    )
}