import { Container,
    TableContainer, 
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell 
} from "@mui/material";
import React from "react";
import { Api } from "../../process/api";


export default function Reviews(){

    const [orders, setOrder] = React.useState([])

    React.useEffect(()=>{

        fetch(Api+'Reviews')
        .then(Response=>Response.json())
        .then(data=>setOrder(data))
    })

    return(
        <Container>
            <TableContainer>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Review</TableCell>
                    </TableRow>
                    
                </TableHead>
                <TableBody>
                    {
                        orders.map((item,index)=>{
                            return(
                                <TableRow key={index}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.review}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </TableContainer>
        </Container>
    )
}