import React from 'react'
import {
    Container,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Avatar,
    Paper,
    Table,
    Button,
    IconButton,
    LinearProgress
} from '@mui/material'
import AddNewImage from './addimage'
import { Api, ImageApi } from '../../process/api'
import SnakBar from '../../consts/massage'
import DeleteIcon from '@mui/icons-material/Delete';

export default function Gallery() {
    
    const [data, setData] = React.useState([])
    const [snackData, setSnackData] = React.useState(null)
    const [refresh, setRefresh] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'GalleryView')
        .then(response=>response.json())
        .then(data=>{
            setLoading(false)
            setData(data)
        })
        .catch(err=>{
            setLoading(false)
            setSnackData({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to server'
            })
        })
    },[refresh])

    const deleteImage = (id)=>{
        setLoading(true)
        fetch(Api+'deleteImage&id='+id)
        .then(response=>response.json())
        .then(data=>{
            setLoading(false)
            console.log(data)
            if(data.status === 201){
                setRefresh(Math.random())
                setSnackData({
                    open : true,
                    severity : 'success',
                    massg : data.massg
                })
            }else {
                setSnackData({
                    open : true,
                    severity : 'error',
                    massg : data.massg
                })
            }
        })
        .catch(err=>{
            setSnackData({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to server'
            })
        })


    }
    const child = ()=>{
        setRefresh(Math.random())
    }
    return(
        <Container>
            <AddNewImage  onSave={child} />
            <TableContainer component={Paper}>
            {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Image Name</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell><Avatar src={ImageApi+'gallery/'+item.image_name} /></TableCell>
                                    <TableCell>{item.image_name}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={()=>deleteImage(item.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {
                snackData != null ? <SnakBar open={snackData.open} severity={snackData.severity} massg={snackData.massg} /> : ''
            }
        </Container>
    )
}

