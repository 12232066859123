import React from "react";

import {
    Container,
    Grid,
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Paper,
    IconButton,
    LinearProgress,
    Divider,
    Typography
} from '@mui/material'
import { Api, ImageApi } from "../../process/api";
import DeleteIcon from '@mui/icons-material/Delete';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditPhys from "./edit";
import Switch from '@mui/material/Switch';

export default function PhyManage(){

    const [physicians, setPhysicians] = React.useState([])
    const [massg, setMassg] = React.useState({
        massg : null,
        severity : null
    })
    const [loading, setLoading] = React.useState(false)
    const [Selected, setSelected] = React.useState()
    const [refresh, setRefresh] = React.useState(null)
    const [checked, setChecked] = React.useState(false);
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'ColPhys')
        .then(Response=>Response.json())
        .then(data=>{
            console.log(data)
            setLoading(false)
            setPhysicians(data)
        })
        .catch(err=>{
            setLoading(false)
                setMassg({
                    massg : "could not connect to the server",
                    severity : 'error'
                })
        })
    },[refresh])

    const handleSelected = (item,test)=>{
        setSelected(item)
        console.log(test)
        setChecked(item.status === 'active' ? true : false)

    }

    const handleChange = (id) => {
       
       
        fetch(Api+'setPhyStatus&id='+id+'&status='+(checked ? 'deactivated' : 'active'))
        .then(response=>response.json())
        .then(data=>{
            console.log(data)
            if(data.status === 201){
                setChecked(!checked)
                setRefresh(Math.random())
            }
        })
    };
    const handleDelete = (id)=>{
        fetch(Api+'deletePhysi&id='+id)
        .then(Response=>Response.json())
        .then(data=>{
            console.log(data)
            if(data.status === 201){
                setRefresh(Math.random())
                setMassg({
                    massg : data.massg,
                    severity : 'success'
                })
            } else {
                setMassg({
                    massg : data.massg,
                    severity : 'error'
                })
            }
            
        })
        .catch(err=>{
            setLoading(false)
                setMassg({
                    massg : "could not connect to the server",
                    severity : 'error'
                })
        })

    }

    const child = ()=>{
        setRefresh(Math.random())
    }

    return(
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                <Paper>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
                {
                    physicians.map((item,index)=>
                        <div key={index} >
                        <ListItem button>
                            <ListItemIcon>
                                <Avatar>
                                <img src={ImageApi+item.profile} width={60} />
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText
                                primary={item.name}
                                secondary={item.department}
                            />
                            <IconButton onClick={()=>handleDelete(item.id)}>
                                <DeleteIcon />
                            </IconButton>
                            <EditPhys onSave={child} data={item} />
                            <IconButton onClick={()=>handleSelected(item,ImageApi+item.profile)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItem>
                        <Divider />
                        </div>
                    )
                }
                </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                   
                    {
                        Selected != null ? (
                            <Paper>
                                <ListItem  button>
                                    <ListItemIcon>
                                        <Avatar>
                                        <img src={ImageApi+Selected.profile} width={60} />
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={Selected.name}
                                        secondary={Selected.department}
                                    />
                                    <Switch
                                        checked={checked}
                                        onChange={()=>handleChange(Selected.id)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary="Email"
                                        
                                    />
                                    <ListItemText
                                        primary={Selected.email}
                                       
                                    />
                                    
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Contact No"
                                        
                                    />
                                    <ListItemText
                                        primary={Selected.ph_no}
                                       
                                    />
                                </ListItem>
                                <ListItem>

                                    <ListItemText
                                        primary="Qualification"
                                      
                                    />
                                    <ListItemText
                                        primary={Selected.qualification}
                                       
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Designation"
                                        
                                    />
                                    <ListItemText
                                        primary={Selected.designation}
                                       
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Gender"
                                        
                                    />
                                    <ListItemText
                                        primary={Selected.gender}
                                       
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Status"
                                    />
                                    <ListItemText
                                        primary={Selected.status}
                                       
                                    />
                                </ListItem>
                                <Typography style={{margin: 10}}>
                                    {Selected.about}
                                </Typography>
                            </Paper>
                        ) : ''
                    }
 
                </Grid>
            </Grid>
        </Container>
    )
}