import React from 'react'
import {
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    TextField,
    LinearProgress,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    Button
} from '@mui/material'
import { Api } from '../../process/api'
import ReplayIcon from '@mui/icons-material/Replay';

export default function Registration(){
    const [data, setData] = React.useState([])
    const [refresh, setRefresh] = React.useState(Math.random())
    const today = new Date()
    const prvious = today.setDate(today.getDate() - 7); 
    const [date, setDate]= React.useState({
        to : new Date().toLocaleDateString("en-CA"),
        from: new Date(prvious).toLocaleDateString("en-CA"),
    })
    const [loading, setLoading] = React.useState(false)
    const [anchor, setAnchor] = React.useState(null)
    const open = Boolean(anchor)
    const handleDate = (event)=>{
        let name = event.target.name;
        let value = event.target.value;

        setDate({
            ...date,
            [name] : value,
            
        })
        
       
        setRefresh(Math.random())
    }

    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'Registration',{
            method : 'POST',
            body : JSON.stringify(date)
        })
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            setData(data)
        })
        .catch(err=>console.log(err))
    },[refresh])

    const handleMenu = (e)=>{
        setAnchor(e.currentTarget)
    }

    const handleFilter = (val)=>{
        setAnchor(null)
        setLoading(true)
        fetch(Api+'RegistrationFilter&filter='+val,{
            method : 'POST',
            body : JSON.stringify(date)
        })
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            setData(data)
        })
        .catch(err=>console.log(err))
    }

    return (
        <Container>
            <Toolbar component={Paper}>
                <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    name="from"
                    style={{marginLeft: 10,}}
                    value={date.from}
                    onChange={handleDate}
                    
                />
                <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    name="to"
                    style={{marginLeft: 10,}}
                    value={date.to}
                    onChange={handleDate}
                    
                />

                <Paper style={{marginLeft : 10}}>
                    <IconButton  onClick={()=>setRefresh(Math.random())}>
                    <Tooltip title="Refresh">
                        <ReplayIcon />
                    </Tooltip>
                    </IconButton>
                </Paper>
                <Button
                    onClick={handleMenu}
                    varaint="outlined"
                    color = "primary"
                >
                    Filter
                </Button>
                <Menu
                    anchorEl={anchor}
                    open={open}
                    onClose={()=>setAnchor(null)}
                >
                    <MenuItem onClick={()=>handleFilter('Person In Distress')}>Person In Distress</MenuItem>
                    <MenuItem onClick={()=>handleFilter('Volunteer')}>Volunteer</MenuItem>

                </Menu>
            </Toolbar>
            {
                loading ? <LinearProgress /> : ''
            }
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableCell>Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Contact No</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Info</TableCell>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell>{item.f_name+' '+ item.l_name }</TableCell>
                                    <TableCell>{item.date}</TableCell>
                                    <TableCell>{item.ph_no}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.a}</TableCell>
                                    <TableCell>{item.a_data}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}