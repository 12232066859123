import React from "react";
import {
    Container, 
    Toolbar,
    IconButton,
    Typography,
    Grid,
    Divider,
    Paper,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    Menu,
    MenuItem,
    Button
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from "react-router";
import { Api } from "../../process/api";
import SnakBar from "../../consts/massage";
export default function AppointDet (props){
    const history = useHistory()
    const propData = props.location.state
    const [loading, setLoading] = React.useState(false)
    const [appointInfo, setAppointInfo] = React.useState({})
    const [userInfo, setUserInfo] = React.useState({})
    const [physiInfo, setPhysiInfo] = React.useState({})
    const [report, setReport] = React.useState(null)
    const [anchorEl, setAnchorE1] = React.useState(null)
    const open = Boolean(anchorEl)
    const [massg, setMassg] = React.useState({})
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'appointMoreDet',{
            method : 'POST',
            body : JSON.stringify({
                appointment_id : propData.id,
                physi_id : propData.physician_id,
                user_id : propData.user_id
            })
        })
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            setAppointInfo(data.appoint_info)
            setUserInfo(data.user_info)
            setPhysiInfo(data.physician_info)
            setReport(data.report.report)
        })
    },[])

    const handleClick = (e)=>{
        setAnchorE1(e.currentTarget)
    }
    const handleStatus = (e)=>{
        setLoading(true)
        setAnchorE1(null)
        fetch(Api+'updateApointStatus',{
            method : 'POST',
            body : JSON.stringify({
                id : propData.id,
                status : e
            })
        })
        .then(res=>res.json())
        .then(data=>{
            console.log(data)
            setLoading(false)
            if(data.status === 201){
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'success'
                })
            }else {
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'error'
                })
            }
        })
        .catch(err=>{
            console.log(err)
            setLoading(false)
            setMassg({
                open : true,
                massg : 'Faild to connect to the server',
                severity : 'error'
            })
        })

    }
    return(
        <Container>
            <Toolbar component={Paper}>
                <IconButton onClick={()=>history.goBack()}>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography>Appointment Details</Typography>
                <Button
                    variant="contained"
                    style={{marginLeft:10}}
                    onClick={handleClick}
                >
                    Change Status
                </Button>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={()=>setAnchorE1(null)}
                >
                    <MenuItem onClick={()=>handleStatus('accepted')}>Accept</MenuItem>
                    <MenuItem onClick={()=>handleStatus('rejected')}>Reject</MenuItem>
                    <MenuItem onClick={()=>handleStatus('completed')}>Completed</MenuItem>
                </Menu>
            </Toolbar>
            {
            loading ? <LinearProgress /> : ''
            }
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                <Paper>
                <TableContainer>
                    <TableBody>
                {
                    Object.keys(appointInfo).map((item,index)=>
                        <TableRow key={index}>
                            <TableCell>{item}</TableCell>
                            <TableCell>{appointInfo[item]}</TableCell>
                        </TableRow>
                    
                    )
                }
                    </TableBody>
                </TableContainer>
                </Paper>

                <Paper>
                <h3>Report Details</h3>
                <Divider />
                {
                    report != null ? (
                        <div dangerouslySetInnerHTML={{__html: report}}>

                        </div>
                    ) : (
                        <div>Report not generated yet</div>
                    )
                }
                </Paper>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Paper>
                        <h3>User Details</h3>
                        <Divider />
                        <TableContainer>
                            <TableBody>
                        {
                            Object.keys(userInfo).map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell>{item}</TableCell>
                                    <TableCell>{userInfo[item]}</TableCell>
                                </TableRow>
                            
                            )
                        }
                            </TableBody>
                        </TableContainer>
                    </Paper>
                    <Paper>
                        <h3>Ambassador Details</h3>
                        <Divider />
                        <TableContainer>
                            <TableBody>
                        {
                            Object.keys(physiInfo).map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell>{item}</TableCell>
                                    <TableCell>{physiInfo[item]}</TableCell>
                                </TableRow>
                            
                            )
                        }
                            </TableBody>
                        </TableContainer>
                    </Paper>
                </Grid>

            </Grid>
            <SnakBar setMassg={setMassg} massg={massg} />
        </Container>
    )
}