
import React from 'react'
import {
    Container,
    Paper,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    LinearProgress
} from '@mui/material'
import { Api } from '../../process/api';


export default function Massages(){
    const today = new Date()
    const prvious = today.setDate(today.getDate() - 7); 
    const [Data, setData]= React.useState([])
    const [date, setDate]= React.useState({
        to : new Date().toLocaleDateString("en-CA"),
        from: new Date(prvious).toLocaleDateString("en-CA"),
    })
    const [loading, setLoading] = React.useState(false)
    const handleDate = (event)=>{
        let name = event.target.name;
        let value = event.target.value;

        setDate({
            ...date,
            [name] : value,
            
        })
    }

    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'massages',{
            method : 'POST',
            body : JSON.stringify(date)
        })
        .then(Response=>Response.json())
        .then(data=>{
            setData(data)
            setLoading(false)
        })
    },[date])

    return(
        <Container maxWidth="xl" disableGutters>
            <Paper style={{display:'flex',justifyContent: 'flex-end',padding: 10,marginBottom: 10}}>
            
            <TextField
                type="date"
                variant="outlined"
                size="small"
                name="from"
               
                value={date.from}
                onChange={handleDate}
                
            />
            <TextField
                type="date"
                variant="outlined"
                size="small"
                name="to"
                style={{marginLeft: 10,}}
                value={date.to}
                onChange={handleDate}
                
            />
            </Paper>

            <TableContainer component={Paper}>
            {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
            <Table  aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">C. No</TableCell>
                    <TableCell align="right">Date & Time</TableCell>
                    <TableCell align="right">Massages</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {Data.map((row) => (
                    <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.c_no}</TableCell>
                    <TableCell align="right">{row.send_at}</TableCell>
                    <TableCell align="right">{row.massg}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

        </Container>
    )
}