import React from 'react'
import {
    Container, Grid, ListItem, ListItemText,Divider, Paper, IconButton, Button,LinearProgress
} from '@mui/material'
import DepartmentInsert from './insertDepartment'
import { Api } from '../../process/api'
import EditIcon from '@mui/icons-material/Edit';
import DepartmentUpdate from './updateDepartment';
export default function DepartManager(params) {
   
    const [Data, setData] = React.useState([])
    
    const [loading,setLoading] = React.useState(false)
    const [refresh, setRefresh] = React.useState(null)
    const [selected, setSelected] = React.useState(<DepartmentInsert setRefresh={setRefresh} />)
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'departments')
        .then(res=>res.json())
        .then(data=>{
            setData(data)
            setLoading(false)
        })
        .catch(err=>console.log(err))
    },[refresh])

    const handleEditClick = (e)=>{
        setSelected(<DepartmentUpdate data={e} setRefresh={setRefresh} />)
    }

    

    return(
        <Container>
            <Grid container spacing={1}>
                <Grid item  xs={12} sm={4}>
                
                <Button variant="contained" onClick={()=>setSelected(<DepartmentInsert setRefresh={setRefresh} />)}>
                    Add new department
                </Button>
                
                <Paper>
                {
                    loading ? <LinearProgress style={{width : '100%'}} /> : ''
                }
                {
                    Data.map((item,index)=>
                        <div key={index}>
                            <ListItem>
                                <ListItemText
                                    primary={item.name}
                                />
                                <IconButton onClick={()=>handleEditClick(item)}>
                                    <EditIcon />
                                </IconButton>
                            </ListItem>
                            <Divider />
                        </div>
                    )
                }
                </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper>
                    {
                        selected
                    }
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}