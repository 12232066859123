import React from "react";
import { 
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Container,
    Button,
    TextField,
    LinearProgress

} from "@mui/material";
import { Api } from "../../process/api";
import { TrainRounded } from "@mui/icons-material";

export default function SlotManager(){
    const [Data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [saveBtn, setSavebtn] = React.useState(true)
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'slots')
        .then(Response=>Response.json())
        .then(data=>{
            setData(data)
            setLoading(false)
        })
        .catch(err=>alert(err))
    },[])

    const onChange = (index,value,row)=>{
        Data[index] = {
            ...Data[index],
            slots : value
        }
        setData([...Data])
        setSavebtn(false)
    }

    const onSubmit=()=>{
        setLoading(TrainRounded)
        fetch(Api+'updateSlots',{
            method : 'POST',
            body : JSON.stringify(Data)
        })
        .then(Response=>Response.json())
        .then(data=>{
            setLoading(false)
            console.log(data)
        })
        .catch(err=>console.log(err))
    }


    return(
        <Container maxWidth="sm">
        <Paper style={{padding: 5, display: 'flex', justifyContent : 'flex-end',marginTop: 5,marginBottom : 5}}>
            <Button onClick={onSubmit} disabled={saveBtn} variant="contained" color="primary">
                save
            </Button>
        </Paper>
        <TableContainer component={Paper}>
        {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
        <Table aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Slots</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {Data.map((row,index) => (
                <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">

                    <TextField
                        variant="standard"
                        value={row.slots}
                        onChange= {({target : {value}})=>onChange(index,value,row)}
                    />
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </Container>
    )
}