import React from "react";
import {
    Container,
    Dialog,
    Button,
    Typography,
    LinearProgress,
    TextField,
    IconButton,
    Tooltip,
    DialogTitle,
    Divider,
    Menu,
    MenuItem
} from '@mui/material'
import {BsFillImageFill} from 'react-icons/bs'
import { Box } from "@mui/system";
import Resizer from "react-image-file-resizer";
import { Api } from "../../process/api";
import SnakBar from "../../consts/massage";
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

export default function AddNews(props) {
    const [open, setOpen]=React.useState(false)
    const [image, setImage] = React.useState('')
    const [snakData, setSnakData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [err, setErr] = React.useState({})
    const [formData, setFormData] = React.useState({})
    const [menu, setMenu] = React.useState(false)
    const [attachment, setAttachment] = React.useState(null)
    const [anchorE1, setAnchorE1] = React.useState(null)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }

    

    const handleMenu = (e)=>{
        setAnchorE1(e.currentTarget)
        setMenu(true)
    }
    const handleText = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const handleMenuValue = (val)=>{
        setFormData({
            ...formData,
            category : val
        })
        setAnchorE1(null)
        setMenu(null)
    }
    const handleImage = (event)=>{
        try {
            Resizer.imageFileResizer(
            event.target.files[0],
            600,
            1000,
            "PNG",
            80,
            0,
            (uri) => {
                
                setFormData({
                    ...formData,
                    image : uri
                });
            },
            "file",
            200,
            200
            );

        
        } catch (err) {
            console.log(err);
        }
    }

    const handleAttachemnt = (e)=>{
        setAttachment(e.target.files[0])
    }

    const validate = ()=>{
        let valid =  true
        let error = {}

        if(!formData.head){
            valid = false
            error['head'] = true
        }
        if(!formData.image){
            valid = false
            error['image'] = true
        }
        if(!formData.head){
            valid = false
            error['head'] = true
        }
        if(!formData.category){
            valid = false
            error['category'] = true
        }
        if(!formData.publish_date){
            valid = false
            error['publish_date'] = true
        }
        if(editorState === EditorState.createEmpty()){
            valid = false
            error['editorState'] = true
        }

        setErr(error)

        return valid
    }

    const onSubmit=()=>{
        if(validate()){
            const newData = new FormData
            newData.append('head',formData.head)
            newData.append('image',formData.image)
            newData.append('category',formData.category)
            newData.append('publish_date',formData.publish_date)
            newData.append('expiry_date',formData.expiry_date)
            newData.append('attachment',attachment)
            newData.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            setLoading(true)
            fetch(Api+'uploadNews',{
                method : 'POST',
                body : newData
            })
            .then(response=>response.json())
            .then(data=>{
                setLoading(false)
                console.log(data)
                if(data.status === 201){
                    setFormData({})
                    setAttachment(null)
                    setOpen(false)
                    setSnakData({
                        open : true,
                        severity : 'success',
                        massg : data.massg
                    })
                }else {
                    setSnakData({
                        open : true,
                        severity : 'error',
                        massg : data.massg
                    })
                }
            })
            .catch(err=>{
                setLoading(false)
                setSnakData({
                    open : true,
                    severity : 'error',
                    massg : 'Faild to connect to server'
                })
            })
        }else { 
            setSnakData({
                open : true,
                severity : 'error',
                massg : 'please fill all required filed'
            })
        }
    }
    return (
        <Container>
           
            <Button
              variant="contained"
              onClick={()=>setOpen(true)}
              
            >
                Add New 
            </Button>
            <Dialog
                open={open}
                onClose={()=>setOpen(false)}
                maxWidth="lg"
                
            >
                

                <DialogTitle id="customized-dialog-title" onClose={()=>setOpen(false)}>
                    Add new 
                    <IconButton
                    aria-label="close"
                    onClick={()=>setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                    <Divider />
                </DialogTitle>
                <Box style={{display: 'flex',flexDirection : 'column',justifyContent: 'center',alignItems: 'center',padding: 20}}>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
                <TextField 
                    label="Heading"
                    variant="outlined"
                    fullWidth
                    style={{margin: 5}}
                    name="head"
                    value={formData.head}
                    onChange={handleText}
                    error={err.head}
                />
                <div style={{display: 'flex',width:'100%',alignItems : 'center'}}>
                    <TextField 
                        
                        variant="outlined"
                        type="file"
                        helperText="set image"
                        fullWidth
                        style={{margin: 5, width: '69%'}}
                        onChange={handleImage}
                        error={err.image}
                    />
                    <Button onClick={handleMenu} style={{backgroundColor : err.category ? 'red' : ''}}>
                        set category
                    </Button>
                    <Menu
                        anchorEl={anchorE1}
                        open={menu}
                        onClose={()=>setMenu(false)}
                        
                    >
                        <MenuItem onClick={()=>handleMenuValue('News')}>News</MenuItem>
                        <MenuItem onClick={()=>handleMenuValue('Event')}>Event</MenuItem>
                        <MenuItem onClick={()=>handleMenuValue('Blog')}>Blog</MenuItem>

                    </Menu>
                </div>
                <div style={{display: 'flex',width:'100%',alignItems : 'center'}}>
                    <TextField 
                        helperText="Publish date"
                        variant="outlined"
                        type="date"
                        fullWidth
                        style={{margin: 5}}
                        name="publish_date"
                        value={formData.publish_date}
                        onChange={handleText}
                        error={err.publish_date}
                    />
                    <TextField 
                        helperText="Expiry date"
                        variant="outlined"
                        type="date"
                        fullWidth
                        style={{margin: 5}}
                        name="expiry_date"
                        value={formData.expiry_date}
                        onChange={handleText}
                        error={err.expiry_date}
                    />
                </div>
                <TextField 
                        
                        variant="outlined"
                        type="file"
                        helperText="set attachment"
                        fullWidth
                        style={{margin: 5}}
                        onChange={handleAttachemnt}
                    />
                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{border : '1px solid ',minHeight : 200, borderColor : err.editorState ? 'red' : '#e5e5e5'}} //err.editorState ? 'red' :
                    toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
                
                    />
                    <textarea
                    disabled
                    style={{display: 'none'}}
                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                    // onChange={({target : {value}})=>setData(value)}
                />
                </Box>
            </Dialog>
            <SnakBar setMassg={setSnakData} massg={snakData} />
            
        </Container>
    )
}