import React from "react";
import {
    Container,
    TextField,
    Button,
    Paper,
    Box,
    InputBase,
    LinearProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material'
import Resizer from "react-image-file-resizer";
import { Api } from "../../process/api";
import SnakBar from "../../consts/massage";
export default function AddPhysicians(){
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [ph_no,setPh_no] = React.useState('')
    const [valid, setValid] = React.useState({})
    const [massg, setMassg] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [price, setPrice] = React.useState('')
    const [department, setDepartment] = React.useState([])
    const [selectedDep, setSelectedDep] = React.useState('')
    
    const validate = ()=>{
        let valid = true
        let error = {}

        if(!name){
            valid =  false;
            error['name'] = true
        }
        if(!email){
            valid =  false;
            error['email'] = true
        }
        if(!ph_no){
            valid =  false;
            error['ph_no'] = true
        }
        if(!price){
            valid =  false;
            error['price'] = true
        }
        if(!selectedDep){
            valid = false;
            error['selectedDep'] = true
        }

        setValid(error)
        return valid
    }
    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
            setMassg(({
                massg : null,
                severity : null
            }))
            const formData = new FormData
            formData.append("name",name)
            formData.append("email",email)
            formData.append("ph_no",ph_no)
            formData.append("price",price)
            formData.append('department',selectedDep)
            fetch(Api+'uploadPhys',{
                method : 'POST',
                body : formData
            })
            .then(Response=>Response.json())
            .then(data=>{
                setLoading(false)
                console.log(data)
                if(data.status === 201){
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'success'
                    })
                }else {
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
               
            })
            .catch(err=>{
                console.log(err)
                setLoading(false)
                setMassg({
                    open : true,
                    massg : "could not connect to the server",
                    severity : 'error'
                })
            })
        }
    }

    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'departments')
        .then(res=>res.json())
        .then(data=>{
          setDepartment(data)
            setLoading(false)
        })
        .catch(err=>console.log(err))
    },[])
    return (
        <Container maxWidth="sm" >
            <Paper>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
                <Box style={{padding: 20,display: 'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center'}}>
                <h3>Add Physician</h3>
                <TextField
                    variant="outlined"
                    label="Enter Name"
                    style={{margin: 10}}
                    fullWidth
                    value={name}
                    onChange={({target : {value}})=>setName(value)}
                    error={valid.name}
                />
                <TextField
                    variant="outlined"
                    label="Enter Email"
                    style={{margin: 10}}
                    fullWidth
                    value={email}
                    onChange={({target : {value}})=>setEmail(value)}
                    error={valid.email}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Department</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Department"
                    name="department"
                    style={{margin: 10}}
                    value={selectedDep}
                    onChange={({target : {value}})=>setSelectedDep(value)}
                    error={valid.selectedDep}
                  >
                    {
                      department.map((item,index)=><MenuItem key={index} value={item.name}>{item.name}</MenuItem>)
                    }
                    
                  </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    label="Enter Contact No"
                    style={{margin: 10}}
                    fullWidth
                    value={ph_no}
                    onChange={({target : {value}})=>setPh_no(value)}
                    error={valid.ph_no}
                />
                <TextField
                    variant="outlined"
                    label="Enter Visiting Charge"
                    style={{margin: 10}}
                    fullWidth
                    value={price}
                    onChange={({target : {value}})=>setPrice(value)}
                    error={valid.price}
                    helperText="Enter numaric value only"
                    type="number"
                />
                

                <Button
                    variant="contained"
                    onClick={()=>onSubmit()}
                >
                    Register
                </Button>

                </Box>
            </Paper>
             <SnakBar setMassg={setMassg} massg={massg} />
            
        </Container>
    )
}