import React from 'react'
import {
    Container,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Avatar,
    Paper,
    Table,
    Button,
    IconButton,
    LinearProgress,
    Tooltip,
    Toolbar
} from '@mui/material'
import AddNews from './addnew'
import { Api, ImageApi } from '../../process/api'
import SnakBar from '../../consts/massage'
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReplayIcon from '@mui/icons-material/Replay';
export default function News(){
    
    const [data, setData] = React.useState([])
    const [snackData, setSnackData] = React.useState(null)
    const [refresh, setRefresh] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'newsevents')
        .then(response=>response.json())
        .then(data=>{
            setLoading(false)
            setData(data)
         
        })
        .catch(err=>{
            setLoading(false)
            setSnackData({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to server'
            })
        })
    },[refresh])

    const deleteNews = (id)=>{
        setLoading(true)
        fetch(Api+'deletenews&id='+id)
        .then(response=>response.json())
        .then(data=>{
            setLoading(false)
            console.log(data)
            if(data.status === 201){
                setRefresh(Math.random())
                setSnackData({
                    open : true,
                    severity : 'success',
                    massg : data.massg
                })
            }else {
                setSnackData({
                    open : true,
                    severity : 'error',
                    massg : data.massg
                })
            }
        })
        .catch(err=>{
            setSnackData({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to server'
            })
        })


    }
    const child = ()=>{
        setRefresh(Math.random())
    }
    return(
        <Container>
           <Toolbar component={Paper} style={{marginBottom : 5}}>
           <AddNews  onSave={child} />
            <IconButton onClick={()=>setRefresh(Math.random())}>
                <Tooltip title="Refresh">
                    <ReplayIcon />
                </Tooltip>
            </IconButton>
            </Toolbar>
            <TableContainer component={Paper}>
            {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Headline</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell><Avatar src={ImageApi+'news/'+item.image} /></TableCell>
                                    <TableCell>{item.head}</TableCell>
                                    <TableCell>{item.category}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={()=>deleteNews(item.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    {/* <TableCell>
                                        <IconButton >
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {
                snackData != null ? <SnakBar open={snackData.open} severity={snackData.severity} massg={snackData.massg} /> : ''
            }
        </Container>
    )
}

