import React from 'react'
import {
    Button,
    Container, Paper, TextField, LinearProgress
} from '@mui/material'
import {Api} from '../../process/api'
import SnakBar from '../../consts/massage'
export default function PasswordManager(){
    const [user_name, setUserName] = React.useState(localStorage.getItem('user_name'))
    const [oldPass, setOldPass] = React.useState('')
    const [newPass, setNewPass] = React.useState('')
    const [cPass, setCPass] = React.useState('')
    const [massg, setMassg] = React.useState({})
    const [err, setErr] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
            fetch(Api+'changePass',{
                method : 'POST',
                body : JSON.stringify({
                    pass : newPass,
                    oldPass : oldPass,
                    user_name : user_name,
                    old_user : localStorage.getItem('user_name')
                })
            })
            .then(res=>res.json())
            .then(data=>{
                console.log(data)
                setLoading(false)
                if(data.status === 201){
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'success'
                    })
                    localStorage.setItem('user_name',user_name)
                }else {
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
            })
            .catch(err=>{
                setLoading(false)
                console.log(err)
                setMassg({
                    open : true,
                    massg : 'Faild to connect to server',
                    severity : 'error'
                })
            })
        }
    }


    const validate = ()=>{
        let valid = true
        let err = {}

        if(!oldPass){
            valid = false
            err['oldPass'] = true
        }
        
        if(!newPass){
            valid = false
            err['newPass'] = true
        }
        
        if(!cPass){
            valid = false
            err['cPass'] = true
        }
        if(!user_name){
            valid = false
            err['user_name'] = true
        }
        
        if(newPass != cPass){
            valid = false
            err['newPass'] = true
            err['cPass'] = true
        }

        setErr(err)

        return valid
        
    }
    
    
    return(
        <Container component={Paper} maxWidth="sm">
        {
            loading ? <LinearProgress /> : ''
        }
            <TextField
                label="User name"
                value={user_name}
                onChange={({target : {value}})=>setUserName(value)}
                error={err.user_name}
                fullWidth
                style={{margin: 5}}
            />
            <TextField
                label="Enter old password"
                value={oldPass}
                onChange={({target : {value}})=>setOldPass(value)}
                error={err.oldPass}
                fullWidth
                style={{margin: 5}}
            />
            <TextField
                label="Enter new password"
                value={newPass}
                onChange={({target : {value}})=>setNewPass(value)}
                error={err.newPass}
                fullWidth
                style={{margin: 5}}
            />
            <TextField
                label="Confirm old password"
                value={cPass}
                onChange={({target : {value}})=>setCPass(value)}
                error={err.cPass}
                fullWidth
                style={{margin: 5}}
            />
            <Button
                variant="contained"
                onClick={onSubmit}
                fullWidth
                style={{margin: 5}}
            >
                Change Password
            </Button>

            <SnakBar setMassg={setMassg} massg={massg} />
        </Container>
    )
}