import React from "react";
import {
    Button,
    Container, Paper, TableContainer, TableHead, Toolbar,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Table,
    LinearProgress,
    TableBody,
    IconButton,
    TextField,
    CircularProgress,
    Tooltip
} from '@mui/material'
import { Api } from "../../process/api";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from "react-router";
import ReplayIcon from '@mui/icons-material/Replay';
const menu = [
    {
        name : 'All time',
        value : 'all_time'
    },
    {
        name : "Today's bookings",
        value : 'today'
    },
    {
        name : "Today's appointments",
        value : 'today_appointments'
    },
    
]
export default function Appointments() {
    const [Data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [filters, setFilters]  = React.useState('all')
    const [refresh, setRefresh] = React.useState(Math.random())
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorE2, setAnchorE2] = React.useState(null)
    const open2 = Boolean(anchorE2)
    const [serachLoad, setSearchLoad] = React.useState(false)
    const [physicians,setPhysicians] = React.useState([])
    const history = useHistory()
    const today = new Date()
    const prvious = today.setDate(today.getDate() - 7); 
    const [date, setDate]= React.useState({
        to : new Date().toLocaleDateString("en-CA"),
        from: new Date(prvious).toLocaleDateString("en-CA"),
    })
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'Appointments&reason='+filters,{
            method: 'POST',
            body : '',
        })
        .then(Response=>Response.json())
        .then(data=>{
            setLoading(false)
            setData(data)
        })
        .catch(err=>console.log(err))

    },[refresh])

    const handleMore = (item)=>{
        history.push('/Home/AppointDet',item)
    }

    const handleFilter=()=>{

    }

    const handleFilterClick = (e)=>{
        setAnchorEl(null);
        if(e==='all_time'){
            setFilters('all')
            setRefresh(Math.random())
        }else if(e==='today'){
            setFilters('today')
            setRefresh(Math.random())
        }else if(e==='today_appointments'){
            setFilters('today')
            setRefresh(Math.random())
        }
        
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);;
        
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleByAmba = (e)=>{
        setAnchorE2(e.currentTarget)
        setSearchLoad(true)
        fetch(Api+'physician_all')
        .then(res=>res.json())
        .then(data=>{
            setPhysicians(data)
            setSearchLoad(false)
        })
        .catch(err=>console.log(err))
    }
    const handleAmbas=(id)=>{
        setAnchorE2(null)
        setFilters('by_amba&id='+id)
        setRefresh(Math.random())
    }

    const handleDate = (event)=>{
        let name = event.target.name;
        let value = event.target.value;

        setDate({
            ...date,
            [name] : value,
            
        })
        
        setFilters('byDate&from='+(name != 'to' ? value : date.from)+'&to='+(name != 'from' ? value : date.to))
        setRefresh(Math.random())
    }
    

    return(
        <TableContainer component={Paper}>
            
            <Toolbar component={Paper}>
                <Button
                    variant="outlined"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    Filter
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        menu.map(item=><MenuItem key={item.name} onClick={()=>handleFilterClick(item.value)}>{item.name}</MenuItem>)
                    }
                    
                    
                </Menu>
                <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    name="from"
                    style={{marginLeft: 10,}}
                    value={date.from}
                    onChange={handleDate}
                    
                />
                <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    name="to"
                    style={{marginLeft: 10,}}
                    value={date.to}
                    onChange={handleDate}
                    
                />
                <Button 
                    onClick={handleByAmba}
                >
                    By ambasaddor
                </Button>
                <Paper>
                <IconButton  onClick={()=>setRefresh(Math.random())}>
                <Tooltip title="Refresh">
                    <ReplayIcon />
                </Tooltip>
                </IconButton>
                </Paper>
                <Menu
                    anchorEl={anchorE2}
                    open={open2}
                    onClose={()=>setAnchorE2(null)}
                >
                {
                    serachLoad ? <CircularProgress /> : ''
                }
                {
                    physicians.map((item,index)=>
                        <MenuItem key={index} onClick={()=>handleAmbas(item.admin)}>{item.name}</MenuItem>
                    )
                }
            
                </Menu>
                
            </Toolbar>
            {
                loading ? <LinearProgress /> : ''
            }
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Appointment Id</TableCell>
                    <TableCell>Payment Id</TableCell>
                    <TableCell>User Email</TableCell>
                    <TableCell>Ambassador</TableCell>
                    <TableCell>Appointment Date</TableCell>
                    <TableCell>Pyment Status</TableCell>
                    <TableCell>More</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {
                Data.map((item,index)=>
                    <TableRow key={index}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.pay_id}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.appoint_for}</TableCell>
                        <TableCell>{item.payment_status}</TableCell>
                        <TableCell>
                            <IconButton onClick={()=>handleMore(item)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            }
            </TableBody>
            </Table>
        </TableContainer>
    )
}