import React from "react";
import {
    Container,
    Dialog,
    Button,
    Typography,
    LinearProgress
} from '@mui/material'
import {BsFillImageFill} from 'react-icons/bs'
import { Box } from "@mui/system";
import Resizer from "react-image-file-resizer";
import { Api } from "../../process/api";
import SnakBar from "../../consts/massage";

export default function AddNewImage(props) {
    const [open, setOpen]=React.useState(false)
    const [image, setImage] = React.useState('')
    const [snakData, setSnakData] = React.useState({
        massg : null,
        severity : null,
        open : false
    })
    const [loading, setLoading] = React.useState(false)
    const inputFile = React.useRef(null)
    const onButtonClick = () => {
        
       inputFile.current.click();
    };

    const handleChangeImage = (event)=>{
        
            
            try {
                Resizer.imageFileResizer(
                event.target.files[0],
                800,
                1000,
                "PNG",
                80,
                0,
                (uri) => {
                    
                    setImage(uri);
                },
                "file",
                200,
                200
                );
    
            
            } catch (err) {
                console.log(err);
            }
        
    }

    const onSubmit=()=>{

        if(image != ''){
            setLoading(true)
            const formData = new FormData
            formData.append('image', image)
            fetch(Api+'toGallery',{
                method : 'POST',
                body : formData
            })
            .then(response=>response.json())
            .then(data=>{
                console.log(data)
                setLoading(false)
                if(data.status === 201){
                    props.onSave()
                    setOpen(false)
                    setSnakData({
                        severity : 'success',
                        open : true,
                        massg : data.massg
                    })
                }else {
                    setSnakData({
                        severity : 'error',
                        open : true,
                        massg : data.massg
                    })
                }
            })
            .catch(err=>{
                setLoading(false)
                setSnakData({
                    severity : 'error',
                    open : true,
                    massg : 'Faild to connect to server'
                })
            })
        }else {
            alert('Please select an image to continue')
        }
    }
    return (
        <Container>
            <input type='file' id='file' onChange={handleChangeImage} ref={inputFile} style={{display: 'none'}}/>
            <Button
              variant="contained"
              onClick={()=>setOpen(true)}
              
            >
                Add New Image
            </Button>
            <Dialog
                open={open}
                onClose={()=>setOpen(false)}
                
            >
                <Box style={{display: 'flex',flexDirection : 'column',justifyContent: 'center',alignItems: 'center',width: 300,height: 140}}>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
                <BsFillImageFill onClick={onButtonClick} />
                <Typography>Select Image to upload</Typography>
                <div>
                    <Button onClick={onSubmit}>
                        upload
                    </Button>
                    <Button color="error" onClick={()=>setOpen(false)}>
                        Cancel
                    </Button>
                </div>
                </Box>
            </Dialog>
            {
                snakData.open ? <SnakBar open={snakData.open} severity={snakData.severity} massg={snakData.massg} /> : ''
            }
            
        </Container>
    )
}